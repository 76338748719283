import React from 'react';
import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import ContactSection from '../components/ContactSection';
import SEO from '../components/SEO';

function ContactPage(props) {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <PageHeadSection title="Privacy Policy" />
      <section
        className="SectionComponent hero section is-block is-relative is-white is-medium"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="content">
            <p>
              While it is true that at Horizon Real Estate Group our core
              business is Real Estate and Finance, our business is built around
              our information systems, database management, and personal
              relationships. In order to maintain a high level of trust with our
              clientele and further grow our own business, we must invest our
              resources in the protection of the information that we collect.
              Therefore, we have created this Privacy Policy in order to
              demonstrate our firm commitment to ensure the protection of your
              information that is collected by us on this site and through all
              communications with you, including telephone conversations, email
              and in-person communications. This Privacy Policy describes how
              Horizon Real Estate Group will handle your personal information
              through www.horizonfargo.com (the “Site”). By accepting the
              Privacy Policy and the Terms of Use in registration, you expressly
              consent to our collection, storage, use and disclosure of your
              personal information as described in this Privacy Policy. This
              Privacy Policy is effective immediately.
            </p>

            <p>
              Horizon Real Estate Group collects personally identifiable
              information from you in order to respond to your requests for
              specific criteria on desirable Commercial Space. Depending on the
              services you request, this information may include, but is not
              limited to, your name, address, ZIP Code, email address, telephone
              number, a login name, password, whether you plan on buying or
              selling, financial information and information about the
              properties in which you are interested and your search criteria.
              If you are seeking to buy or sell a property with another person,
              we will collect much of the same information about them as well.
            </p>

            <p>
              Horizon Real Estate Group will not sell, convey or use this
              information in a way that we are unauthorized to do so or that
              could be harmful to you as our client. The use of your information
              will be solely to provide you with the best possible services that
              you deserve and which will be based on your own desired search
              criteria. We will at times use your information to better
              represent you to sellers, landlords, developers, and brokers, but
              in these instances we will have gained your approval to do so.
            </p>

            <p>
              We appreciate your continued support and recognition of our
              services.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
